import React from "react";

function WhatsAppLink({ number, text, greetingText, name }) {
  // Ensure name and messages are properly formatted
  const msg = text.trim().replace(/\n/g, "%0D%0A");
  const greetingMsg = greetingText.trim();
  const nameText = name?.trim(); // Ensure name is not undefined

  // Construct message dynamically
  let fullMessage = "";

  if (greetingMsg && nameText) {
    fullMessage = `${greetingMsg} ${nameText},`; // Greeting and name on one line
  } else if (greetingMsg) {
    fullMessage = `${greetingMsg},`; // Only greeting
  } else if (nameText) {
    fullMessage = `${nameText},`; // Only name
  }

  if (msg) {
    fullMessage += (fullMessage ? `%0D%0A` : "") + msg; // Add new line before msg if greeting/name exists
  }

  return (
    <button
      className="send-whatsapp-button"
      onClick={() =>
        window.open(
          `https://api.whatsapp.com/send?phone=+91%20${number}&text=${fullMessage}`
        )
      }
    >
      Send WhatsApp
    </button>
  );
}

export default WhatsAppLink;