

import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import WhatsAppLink from "./WhatsAppLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp, faYoutube, faLinkedinIn, faFacebookF} from "@fortawesome/free-brands-svg-icons";

// import { db } from "./Firebase";


function FrontPage() {
  const [message, setMessage] = useState(localStorage.getItem("message") || "");
  const [greetingMessage, setGreetingMessage] = useState(localStorage.getItem("greetingMessage") || "");

  const [fileState, setFileState] = useState({
    data: JSON.parse(localStorage.getItem('data')) || [],
    columns: JSON.parse(localStorage.getItem('columns')) || [],
  });

  //to make change in the fileKeys to maintain different files
  const [fileKey, setFileKey] = useState(Date.now()) 


  const handleFileRead = (e) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      let data = e.target.result;
      let workbook = XLSX.read(data, { type: 'binary' });
      let firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      let dataArray = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
  
      if (dataArray.length === 0) {
        alert("The uploaded file is empty!");
        return;
      }
  
      let columns = dataArray[0]; // Extract column headers
      let rows = dataArray.slice(1); // Extract data
  
      // Ensure every row has at least the required columns
      let formattedData = rows.map(row => {
        return [
          row[0] || "",  // Name (If empty, keep it blank instead of shifting values)
          row[1] || "",  // Number
        ];
      });
  
      setFileState({ 
        data: formattedData, 
        columns: ["Name", "Number"] // Explicitly set column headers
      });
  
      // Store data in local storage
      localStorage.setItem("fileData", JSON.stringify({ data: formattedData, columns: ["Name", "Number"] }));
    };
    reader.readAsBinaryString(e.target.files[0]);
    setFileKey(Date.now());
  };

  

  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleSendWhatsApp = () => {
    if (!phoneNumber) {
      alert("Please enter a valid phone number");
      return;
    }
  
    const msg = message.trim().replace(/\n/g, "%0D%0A"); 
    const greetingMsg = greetingMessage.trim();
    const nameText = name.trim();
  
    let fullMessage = "";
  
    if (greetingMsg && nameText) {
      fullMessage = `${greetingMsg} ${nameText},`; // Greeting and name on one line
    } else if (greetingMsg) {
      fullMessage = `${greetingMsg},`; // Only greeting
    } else if (nameText) {
      fullMessage = `${nameText},`; // Only name
    }
  
    if (msg) {
      fullMessage += (fullMessage ? `%0D%0A` : "") + msg; // New line before msg only if there's greeting/name
    }
  
    window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${fullMessage}`);
  };

  const handleContactUs = () => {
    const link = "https://whatsapp-to-non-contact.fairshare.tech/";
    window.open(`https://api.whatsapp.com/send?phone=9850830247&text=Hello%20Fair%20Share%20Support,%0AI%20need%20more%20information%20about%20${link}`)
  }

  // retrieving the data of the app in local storage 

  useEffect(() => {
    localStorage.setItem('message', message);
  }, [message]);

  useEffect(() => {
    localStorage.setItem('greetingMessage', greetingMessage);
  }, [greetingMessage]);

  useEffect(() => {
    localStorage.setItem('data', JSON.stringify(fileState.data));
    localStorage.setItem('columns', JSON.stringify(fileState.columns));
  }, [fileState]);

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    localStorage.setItem("message", e.target.value);

  }; 
  
  const handleGreetingMessageChange = (e) => {
    setGreetingMessage(e.target.value);
    localStorage.setItem("greetingMessage", e.target.value);

  };

  // parsing and reading the data of the xlsx file using xlsx library
  

  // this is to download the sample xlxs file 
  const downloadSampleExcel = () => {

    // sample array of objects
    const data = [
      {  name: "Name", number: "Number" },
      {  name: "John", number: "123456" },
      {  name: "Jane", number: "654321" },
    ];
  
    // creating a CSV formatted string from an array of objects
    const csvData =
      "data:text/csv;charset=utf-8," +
      data.map((d) => `${d.name},${d.number}`).join("\n");
  
    const encodedUri = encodeURI(csvData);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sample-excel.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



  //onClick property for faSave Icon

  const handleSave = () => {
    // Save the data to localStorage
    const dataToSave = {
      message,
      greetingMessage,
      name,
      phoneNumber,
    };
    localStorage.setItem("savedData", JSON.stringify(dataToSave));

    // Clear the form fields
  
  };

  //onClick property for faUndo Icon

  const handleUndo = () => {
    const savedData = JSON.parse(localStorage.getItem('savedData'));
    if (savedData) {
      setMessage(savedData.message);
      setGreetingMessage(savedData.greetingMessage);
      setName(savedData.name);
      setPhoneNumber(savedData.phoneNumber);
    }
  };

  const [whatsappLink, setWhatsappLink] = useState();

  const handleClone = () => {
    const savedData = JSON.parse(localStorage.getItem("savedData"));
    if (savedData) {
      const { message, greetingMessage, name, phoneNumber } = savedData;
      const encodedMessage = encodeURIComponent(message);
      const encodedGreetingMessage = encodeURIComponent(greetingMessage);
      const encodedName = encodeURIComponent(name);
      const encodedPhoneNumber = encodeURIComponent(phoneNumber);
      const link = `https://api.whatsapp.com/send?phone=${encodedPhoneNumber}&text=${encodedGreetingMessage}%20${encodedName},`;
      navigator.clipboard.writeText(`${message}\n\n${link}`);
      setWhatsappLink(link);
      localStorage.setItem("whatsappLink", link);
    }
  };

  return (
    <div className="front-page-view">
      <header>
          Designed and Developed by <a href="http://fairshare.tech/" target="_blank" rel="noopener">Fair Share IT Services</a>
        </header>
        <ul className="contact-us-icon-wrapper">
          <li>
            <a href="#" target = "_blank" onClick={handleContactUs}>
              <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" size="md" title="Contact Us on WhatsApp"/>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/fair-share-it-services-pvt-ltd/" target = "_blank" rel="noreferrer" >
              <FontAwesomeIcon icon={faLinkedinIn} className="linkedin-icon" size="md" title="Contact Us on LinkedIn"/>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/people/FairShare/100057219049062/?mibextid=ZbWKwL" target = "_blank" rel="noreferrer" >
              <FontAwesomeIcon icon={faFacebookF} className="facebook-icon" size="md" title="Contact Us on Facebook"/>
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/watch?v=bxRITKxctoE" target = "_blank" rel="noreferrer" >
              <FontAwesomeIcon icon={faYoutube} className="youtube-icon" size="md" title="Watch Us on YouTube"/>
            </a>
          </li>
        </ul>
        
        
        
      
        <p />
      <h1 className="description">Send Message On WhatsApp Without Saving A Number</h1>
      <textarea placeholder="Enter your text here" value={message} onChange={handleMessageChange} rows="6"/>
      <textarea placeholder="Enter greetings!" value={greetingMessage} onChange={handleGreetingMessageChange} defaultValue="Hello" rows="1" style={{ height: "40px" }} /> <br />
      <input type="text" placeholder="Name (optional)" value={name} onChange={handleNameChange} className="name-field"/> <br />
      <input type="text" placeholder="Phone Number (required)" value={phoneNumber} onChange={handlePhoneNumberChange} className = "number-field"/> <br />
      <div className="send-btn-wrapper">
        <button onClick={handleSendWhatsApp} className="single-send-btn">Send WhatsApp</button>
        <FontAwesomeIcon icon={faClone} onClick={handleClone} className="copy-icon" size="lg" title="Copy to clipboard"/>
      </div>
      <div className="save-recall-wrapper">
        <FontAwesomeIcon icon={faSave} className="save-icon" size="lg" title="Save for Self" onClick={handleSave}/>
        <FontAwesomeIcon icon={faUndo} className="recall-icon" size="lg" title="Recall" onClick={handleUndo}/>
      </div>
      <h3 className="and-or-head">And/Or</h3>
      <h3 className="import-csv">Import a CSV file with Two columns: name & number</h3>
      <input key={fileKey} type="file" onChange = {handleFileRead} className = "choose-input"/> 
      <a href="#" onClick={downloadSampleExcel}>Click here to download a Sample csv</a>
      {/* <button onClick={downloadSampleExcel} className="downloadBtn">click here to download a Sample csv</button> */}
        {fileState.data.length > 0 && (
          <table className="my-table">
            <thead>
              <tr>
                <th>Sr. No.</th>
                {fileState.columns.map((col, index) => (
                  <th key={index + 1}>{col}</th>
                ))}
                <th>Send Message</th>
              </tr>
            </thead>
            <tbody>
              {fileState.data.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex + 1}>{cell}</td>
                  ))}
                  <td>
                    <WhatsAppLink number={row[1]} text={message} greetingText={greetingMessage} name={row[0]}/>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <footer className="py-3">
          Designed and Developed by <a href="http://fairshare.tech/" target="_blank" rel="noreferrer" >Fair Share IT Services</a>
        </footer>
        <a href="#" onClick={handleContactUs}>CONTACT US</a>
    

      </div> 
    
    );

  }

export default FrontPage;